@use "./mixins/respond.scss";


html{
  font-size: 62.5%;
  scroll-behavior: smooth;
  /* We want 10px as root font size.. it's 16px by default
  Change to 62.5% to get 10px  */
  // TLDR: THIS DEFINES WHAT 1 REM IS! 


  @include respond.respond(tab-land){ // Asks: width < 1200?
      font-size: 56.25%;  //1rem = 9px, 9/16 = 56.25%
  }

  @include respond.respond(tab-port){ // asks: width < 900?
      font-size: 50%; // //1rem = 8px, 8/16 = 50%
  }

  @include respond.respond(big-desktop){
      font-size: 75%; //1rem = 12px, 12/16 = 75%
  }
}

.app{
  font-family: var(--font-base);
  background-color: #fafafa;
}

.pill{
  position: absolute;
  height: 8px;
  width: 1rem;
  background: var(--color-primary);
  border-radius: 10px;
  display: inline-block;
  top: -1.8rem;
  left: 0;
  // top: -100%;
  // opacity: 1;

}

.header-text{
  font-size: 5.8rem;
  font-weight: 600;
  color: var(--font-color-dark1);

  @include respond.respond(phone){
    font-size: 4.8rem;
}
}

.header-text-2{
  font-size: 3.6rem;
  font-weight: 400;
  color: var(--color-grey-text);
}

.header-text-3{
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-grey-text);
}

.p-text{
  font-size: 1.9rem;
  font-weight: 300;
  opacity: .7;

}

.color-primary{
  color: var(--color-primary);
}

.color-primary-dark{
  color: var(--color-primary-dark);
}

.app__container {
    width: 100%;
    min-height: 100vh;

    // Overflow and position: keep watermark contained
    overflow: hidden;
    position: relative;
    
  
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column; 
    padding: 4rem 2rem;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }

  .margin-top-1{
    margin-top: 1.6rem;
  }

  .margin-top-2{
    margin-top: 2rem;
  }

  .margin-top-3{
    margin-top: 3rem;
  }

  .margin-top-4{
    margin-top: 4rem;
  }

  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cbcbcb;
      margin: 0.5rem;
  
      transition: var(--color-primary) 0.2s ease-in-out;
  
      &:hover {
        background-color: var(--color-primary);
      }
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
      }
    }
  }