@use "../../mixins/respond.scss";

.contact-container{
  overflow: hidden;
    width: 80%;
    max-width: 120rem;
    margin-top: 3rem;
  
    display: flex;
    flex-direction: column;
  
    align-items: center;
    justify-content: center;

    
}

.contact {
  flex: 1;
  width: 100%;

  &-header {
    position: relative;
  }

  &__secondary-header {
    opacity: 0.9;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.contact_watermark{
    position: absolute;
    color: var(--color-primary-dark);
    font-size: 72rem;
    opacity: .017;
    pointer-events: none;
    bottom: 30%;
  }

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;


  @include respond.respond(tab-port){
    width: 90%;
  }

  margin: 1rem 2rem;

  &__group {
    width: 100%;
  }

  &__group:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  textarea {
    height: 150px;
  }

  &__input {
    // for some reason inputs don't automatically inherit font settings
    // must set manually like so..
    font-family: inherit;
    font-size: 1.6rem;
    padding: 1.6rem;
    border-radius: 8px;
    border: none;
    background-color: #3aafa90c;
    

    // take up the whole area.. block structuring
    width: 100%;
    display: block;

    color: var(--font-color-dark1);

    &:focus {
      outline: none; // ugly blue border on focus
      border: none;
    }

    &::-webkit-input-placeholder {
      color: var(--color-grey-text);
      opacity: 0.7;
    }
  }
  &__btn {
    font-size: 1.6rem;
    padding: 1.6rem 3.2rem;
    border-radius: 0.75rem;
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary);
    color: #f2f3f4;
    font-weight: 300;

    display: inline-block;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 1rem;

    &:hover {
      background-color: var(--secondary-color);
      color: var(--color-primary);
    }
  }
}
