@use "../../mixins/respond.scss";

.education {
  position: relative;

  flex: 1;
  width: 100%;


  &-header {
    position: relative; // for header pill
    display: inline-block;
    width: fit-content;
  }

  &-container {
    position: relative;
    max-width: 120rem;
    width: 80%;

    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include respond.respond(tab-port){
      max-width: 85%;
      flex-direction: column;
      padding: 3rem;
      width: 100%;
      align-items: flex-start;
    }

    @include respond.respond(phone){
      max-width: 100%;
    }
  }

  &-info {
    flex: 1;

    color: var(--color-grey-text);

    margin-right: 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    justify-content: center;

    @include respond.respond(tab-port){
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      margin-right: 0;
      
    }
  }
}

// WSU watermark
.education-img {
  position: absolute;
  bottom: 15%;
  width: 70%;
  max-width: 550px;

  transform: rotate(15deg);
  opacity: 0.06;

  @include respond.respond(tab-port){
    bottom: 10%;
    width: 80%;

  }

  @include respond.respond(phone){
    bottom: 35%;
    width: 90%;

  }

  //unclickable, undraggable, etc.
  pointer-events: none;
}

.education-courses {
  flex: 1;
  margin-left: 1rem;
  opacity: 0.7;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include respond.respond(tab-port){
    margin-top: 3rem;
    margin-left: 0;
    // flex: 1 0 10%;
    flex-direction: row;
    
  }

  p {
    font-size: 1.6rem;
    margin: 0.8rem 0;
  }
}

// Individual course container
.courses-item {
  display: flex;
  align-items: center;

  // Creates two columns 
  @include respond.respond(tab-port){
    width: 50%;
  }


    
  // animate chevron on hover
  &:hover .courses-item-icon {
    padding-right: 0.1rem;
    font-weight: 700;
    transform: scale(1.25);
  }

  // Chevron icon
  &-icon {
    transition: all 0.2s;
    color: var(--color-primary);
    padding-right: 0.5rem;
    font-size: 2.4rem;
  }
}

.university-name {
  font-weight: 500;
  // opacity: .7;
  color: var(--font-color-dark1);

  @include respond.respond(phone){
    font-size: 2.4rem;
  }
}
