*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}




@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root {
    --font-base: "Inter", sans-serif;
    --color-primary: #3AAFA9;
    --color-primary-dark: #007f7a;

    --color-grey-text: #6b7280;
    --color-grey-text-darker: #5e6570;


    --bg-teal: #f7ffff;
    --bg-teal2: #EEF5F9;
    --bg-blueish: #eef5f9;
    --bg-blueish2: #eef5f9;

    --bg-greyish: #f5fbfa;
    --bg-color: #edf2f8;

    --bg-offwhite:#fafafa;

    --font-color-dark1: #4c5857;
    --font-color-dark2: #3e4645;
    --font-color-lighter: #6d7877;

    

}

::selection{
    background-color: var(--color-primary);
    color: var(--bg-offwhite);
}