@use "../../mixins/respond.scss";

.work {
  flex: 1;
  width: 100%;
}

.work-container {
  position: relative;
  width: 80%;
  max-width: 120rem;
  margin-top: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond.respond(tab-port) {
    max-width: 85%;
    padding: 3rem;
    width: 100%;
  }

  @include respond.respond(phone) {
    max-width: 100%;
  }
}

// header of the section
.work-info {
  flex: 1;
}

// individual project
.project {
  margin-top: 6rem;
  flex: 1;
  display: flex;
  flex-direction: row;

  align-items: center;

  @include respond.respond(tab-port) {
    flex-direction: column;
  }

  // Container for all information - title, desc, etc
  &-info {
    flex: 1;

    // container whose purpose is to keep the text from going too close to the images
    &-container {
      display: flex;
      flex-direction: column;
      width: 90%;

      @include respond.respond(tab-port) {
        width: 100%;
      }
    }
  }

  // Project title
  &-title {
    font-size: 3.6rem;
    font-weight: 700;
    color: var(--font-color-dark1);
  }

  // Project description
  &-desc {
    margin-top: 3.2rem;
    line-height: 1.6;
  }

  &-links {
    margin-top: 1rem;

    &-button {
      text-decoration: none;

      margin: 1rem 0.25rem;
      color: var(--color-primary);
      padding: 0.5rem 2.5rem;
      display: inline-block; // necessary for effect!
      font-size: 1.6rem;
      transition: all 0.2s;
      backface-visibility: hidden;
      border: 2px solid var(--color-primary);
      cursor: pointer;

      &:hover {
        backface-visibility: hidden;
        color: white;
        background-color: var(--color-primary);
      }
    }
  }

  // Display image of the project
  &-visual {
    display: flex;
    flex: 1;
  }
}

.tech-list {
  margin-top: 2.4rem;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.tech-item {
  margin: 1.2rem;
  flex-direction: column;
  text-align: center;

  // Skill name
  &-text {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    color: var(--color-grey-text);
  }

  // container for skill image
  &-imgcontainer {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #fff; // default value

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 25px #fef4f5;
    }

    // when hovered over imgcontainer, animate the image
    &:hover img {
      transform: scale(1.2);
    }

    img {
      width: 50%;
      height: 50%;
    }

    @media screen and (min-width: 2000px) {
      width: 7rem;
      height: 7rem;
    }

    @media screen and (max-width: 450px) {
      width: 5rem;
      height: 5rem;
    }
  }
}

.project-img-container {
  flex: 1;

  max-width: 70rem;

  img {
    width: 100%;
  }
}
