// MEDIA QUERY MANAGER

// 0 - 600px phone
// 600 - 900 tablet portrait
// 900 - 1200 tablet landscape
// {1200 - 1800} "normal" styles
// 1800 + big desktop

// rems seem to fail in media queries; ems are better
// not effected by what we did above
// 1 em = 16px


// ORDER:
// Base/typography => general layout => page layout => components

@mixin respond($breakpoint){    // 600px
    @if $breakpoint == phone{
        @media (max-width: 37.5em){@content}    
    };
    @if $breakpoint == tab-port{    // 900px
        @media (max-width: 56.25em){@content}
    };
    @if $breakpoint == tab-land{    // 1200px
        @media (max-width: 75em){@content}    
    };
    @if $breakpoint == big-desktop{ // 1800px
        @media (min-width: 112.5em){@content}
    }
  }