@use "../../mixins/respond.scss";

.skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
  overflow: hidden; // watermark
  
}

.skills-header {
  color: var(--font-color-dark1);
  position: relative;

}

.skills_watermark {
  position: absolute;
  color: var(--color-primary-dark);
  font-size: 72rem;
  opacity: 0.02;
  pointer-events: none;
}

// Container of the whole section
.skills-container {
  position: relative;
  width: 80%;
  max-width: 120rem;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include respond.respond(tab-port){
    flex-direction: column;
    padding: 3rem;
    max-width: 85%;
    width: 100%;
  }


  @include respond.respond(phone){
    max-width: 100%;
  }
}

//  ** LEFT SIDE OF SECTION **

// List of all skills and
.skills-list {

  @include respond.respond(tab-port){
    order: 2;
    margin-top: 4.8rem;
  }


  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  

  @include respond.respond(tab-port){
    margin-right: 0;
  }
}

.skills-item {
  // flex: 1;
  margin: 1.6rem;
  flex-direction: column;
  text-align: center;

  // Skill name
  &-text {
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 0.5rem;
    color: var(--color-grey-text);

    @include respond.respond(phone){
      font-size: 1rem;    }
  }

  // container for skill image
  &-imgcontainer {
    // 70px x 70px = default value
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: #fff; // default value

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 25px #fef4f5;
    }

    // when hovered over imgcontainer, animate the image
    &:hover img {
      transform: scale(1.2);
    }

    img {
      width: 50%;
      height: 50%;
    }

    @media screen and (min-width: 2000px) {
      width: 10rem;
      height: 10rem;
    }

    @include respond.respond(phone){
      width: 4rem;
      height: 4rem;
    }
  }
}

// ** RIGHT SIDE OF SECTION **

// Container holding all text,
.skills-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left; // would be this anyway; just set explicitly
  justify-content: center;

  /// Text under title
  &-about {
    margin-top: 1.6rem;
    line-height: 1.5;
    color: var(--color-grey-text);
  }
}

// List of tags
.skills-filter {
  margin-top: 1.6rem;
  display: flex;

  @include respond.respond(tab-port){
    margin-top: 2.4rem;
  }
}

// buttons for tags
.skills-filter-item {
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: white;
  font-weight: 700;

  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem;

  &:hover {
    background-color: var(--secondary-color);
    color: var(--color-primary);
  }

  // @media screen and (min-width: 2000px) {
  //   padding: 0.75rem 1.5rem;
  //   border-radius: 0.85rem;
  // }
}

.item-active {
  background-color: var(--color-grey-text);
  color: #fff;
}

.active-skill {
  transition: 0.35s;
  transform: scale(1.2);
}

.non-active-skill {
  transition: 0.35s;
  transform: scale(1);
  opacity: 0.9;
  color: #d2edfc;
}

.skill-fadeout {
  transition: opacity 0.35s;
  opacity: 0.2;
  position: relative;
}

.skill-fadein {
  transition: opacity 0.35s;
  opacity: 1;
}