@use "../../mixins/respond.scss";

.header-container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    width: 80%;
    max-width: 100rem;


    @include respond.respond(tab-port){
        flex-direction: column;
        align-items: flex-start;
    }
}

.header-info{
    display: flex;
    flex: 1.5;
    overflow: hidden;

    @include respond.respond(tab-port){
        flex: 1;
    }

    &-container{

        display: flex;
        flex-direction: column;
        width: 95%;

        @include respond.respond(tab-port){
            width: 100%;
            justify-content: center;
        }
    }
}

.header-img{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond.respond(tab-port){
        flex: 0;
        display: none;
    }

    img{
        width: 80%;

        @include respond.respond(tab-port){
            width: 90%;
        }
    }

    
}


.header-links{
    list-style: none;

    &-item{
        position: relative; // so icon title effect works
        display: inline-block;
        padding: 0 3.2rem 0 0;

        &:hover .header-links-icon{
           
            color: var(--color-primary-dark);
            transform: scale(1.5);
            
        }
    }

    &-icon{
        font-size: 3rem;
        color: var(--font-color-dark1);
        transition: all .25s;  
    }



}

.about-me{
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 80vh;
    margin-bottom: 4rem;

    &__info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        &--title{
            display: inline-block;
            margin-bottom: 2rem;
            position: relative; // for underline effect **
        }

        &--subtitle{
            display: block;
            font-size: 3rem;
            margin-bottom: 2rem;
            position: relative; // for underline effect** 
            width: fit-content;
        }
    
        &--para{
            display: inline-block;
            font-size: 2rem;
            line-height: 1.6;
        }

        &--list{
            margin-top: 2rem;
            list-style: none;
            
            &-item{ // icons for github, linkedin, etc
                position: relative; // so icon title effect works
                display: inline-block;
                padding: 0 3.2rem 0 0;

                &:hover .about-me__info--list-icon{
                    color: var(--color-primary);
                    transform: scale(1.2);

                }
            }

            &-icon{
                font-size: 3rem;
                color: var(--color-grey-dark-1);
                transition: all .25s;  
            }

            &-name{ // label for github, linkedin, etc
                // absolute positioning; it won't have an effect on parents size or position
                position: absolute;
                font-size: 1.6rem;
                top: 5rem;
                right: 2rem;                
                // For zoom in name effect
                // start from size 0 opacity 0..
                // go to full size and opaque
                opacity: 0;
                transform: scale(0);
                transition: all .25s;

            }

            &--span{
                display: inline-block;
                position: relative;
            }
        }
    }

    &__img{ // large svg icon
        // width 100% prevents image from overflowing out of the parent --container 
        width: 70%;

        &--container{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.span2{
    margin-top: 3rem;
}